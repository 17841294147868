import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    component: () => import('@/views/index'),
    children: [
      {
        path: '/home',
        name: '/home',
        component: () => import('@/views/home/index'),
        meta: { title: '首页' }
      },
      {
        path: '/data',
        name: '/data',
        component: () => import('@/views/data/index'),
        meta: { title: '指数数据' }
      },
      {
        path: '/comment',
        name: '/comment',
        component: () => import('@/views/comment/index'),
        meta: { title: '指数点评' }
      },
      {
        path: '/comment/:id',
        name: '/comment',
        component: () => import('@/components/comDetail/index.vue'),
        meta: { title: '指数点评' }
      },
      {
        path: '/news',
        name: '/news',
        component: () => import('@/views/news/index'),
        meta: { title: '新闻资讯' }
      },
      {
        path: '/news/:id',
        name: '/news',
        component: () => import('@/components/newsDetail/index.vue'),
        meta: { title: '新闻资讯' }
      },
      {
        path: '/enter',
        name: '/enter',
        component: () => import('@/views/enter/index'),
        meta: { title: '优质企业' }
      },
      {
        path: '/enter/:id',
        name: '/enter',
        component: () => import('@/components/enterDetail/index.vue'),
        meta: { title: '优质企业' }
      },
      {
        path: '/introduce',
        name: '/introduce',
        component: () => import('@/views/introduce/index'),
        meta: { title: '指数介绍' }
      },
      {
        path: '/about',
        name: '/about',
        component: () => import('@/views/about/index'),
        meta: { title: '关于白沟' }
      },
      {
        path: '/about/:id',
        name: '/about',
        component: () => import('@/components/aboutDetail/index'),
        meta: { title: '关于白沟' }
      },
    ]
  },
  {
    path: '/phone',
    component: () => import('@/views/phone/index')
  },
  {
    path: '/phone/home',
    component: () => import('@/views/phone/index')
  },
  {
    path: '/phone/data',
    component: () => import('@/views/phoneData/index')
  },
  {
    path: '/phone/comment',
    component: () => import('@/views/phoneComment/index')
  },
  {
    path: '/phone/comment/:id',
    component: () => import('@/components/phoneComDetail/index')
  },
  {
    path: '/phone/news',
    component: () => import('@/views/phoneNews/index')
  },
  {
    path: '/phone/news/:id',
    component: () => import('@/components/phoneNewsDetail/index.vue')
  },
  {
    path: '/phone/enter',
    component: () => import('@/views/phoneEnter/index')
  },
  {
    path: '/phone/enter/:id',
    component: () => import('@/components/phoneEnterDetail/index')
  },
  {
    path: '/phone/introduce',
    component: () => import('@/views/phoneIntroduce/index')
  },
  {
    path: '/phone/about',
    component: () => import('@/views/phoneAbout/index')
  },
  {
    path: '/phone/about/:id',
    component: () => import('@/components/phoneAboutDetail/index.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
