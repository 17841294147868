import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/style/common.less'
/* svg */
import SvgIcon from '@/components/SvgIcon'
import './assets/icons/index'
Vue.component('svg-icon',SvgIcon)
/* <svg-icon icon-fill-name='xxxx'/>*/
/* el */
import ElementUI from 'element-ui';
import '../node_modules/element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
/* echarts */
import * as echarts from 'echarts'
/* swiper */
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.css'
/* 轮循 */
import vueSeamlessScroll from "vue-seamless-scroll";
Vue.component('vueSeamlessScroll',vueSeamlessScroll)
/* vant */
import Vant from 'vant';
import '../node_modules/vant/lib/index.css'
Vue.use(Vant);
import ViewUI from 'view-design'
import '../node_modules/view-design/dist/styles/iview.css'
Vue.use(ViewUI);
// Vue.prototype.baseUrl='https://api.idx365.com'
Vue.prototype.baseUrl=''
import navBar from '@/components/navBar/index.vue'
Vue.component('navBar',navBar)
import PhoneFooter from '@/components/phonefooter/index.vue'
Vue.component('phoneFooter',PhoneFooter)
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
