<template>
    <div class="phone-footer">
        <div>
                <div>主办单位：<a href="http://bgxc.gov.cn/" target="_blank" rel="noopener noreferrer">白沟新城管理委员会</a></div>
            <div>研发单位：<a href="http://www.shuliangtec.com/" target="_blank" rel="noopener noreferrer">杭州数亮科技股份有限公司</a></div>
  
   
            <div>承办单位：<a href="http://bgxc.gov.cn/" target="_blank" rel="noopener noreferrer">白沟新城改革发展科技局</a></div>
            <div>备案号:<a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">皖ICP备2022014318</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
}
</script>
<style lang="less" scoped>
.phone-footer {
    text-align: left;
    margin-top: 0.15rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 0.8rem;
    padding: 0.1rem 0.05rem;
    background-color: #885a31;
    font-size: 0.12rem;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    // font-weight: 400;
    color: #FFFFFF;
}

a {
    text-decoration: none;
    color: #333;
}

a:hover,
a:visited,
a:link,
a:active {

    color: #fff;
}
</style>