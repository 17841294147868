<template>
    <div class="nav-bar">
        <van-nav-bar :title="title"  @click-left="onClickLeft" @click-right="onClickRight">
            <template #left>
                <van-icon name="wap-nav" color='#fff' size="0.18rem" />
            </template>
            <template #right>
                <van-icon name="wap-home-o" color='#fff' size="0.18rem" />
            </template>
        </van-nav-bar>
        <van-popup v-model="show" :style="{ height: '100%' }" closeable position="left">
            <div class="list_nav">
                <el-tree :data="treeData" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
            </div>
        </van-popup>
    </div>
</template>
<script>
export default {
    props:['title'],
    data() {
        return {
            show: false,
            title: '首页',
            treeData: [{
                label: '首页',
                title: '首页',
                path: '/phone/home'
            },
            {
                label: '指数数据',
                children: [{
                    label: '产品价格指数',
                    title: '产品价格指数',
                    path: '/phone/data',
                    num: '561',
                    state: 'root',
                    children: [
                        {
                            label: '男包',
                            title: '产品价格指数-男包',
                            num: '561',
                            path: '/phone/data',
                            state: '01'
                        },
                        {
                            label: '女包',
                            title: '产品价格指数-女包',
                            path: '/phone/data',
                            num: '561',
                            state: '02'
                        },
                        {
                            label: '双肩包',
                            title: '产品价格指数-双肩包',
                            path: '/phone/data',
                            num: '561',
                            state: '03'
                        },
                        {
                            label: '旅行箱包',
                            title: '产品价格指数-旅行箱包',
                            path: '/phone/data',
                            num: '561',
                            state: '04'
                        },

                    ]
                },
                {
                    label: '产业发展指数',
                    title: '产业发展指数',
                    path: '/phone/data',
                    num: '562',
                    state: 'root',
                    children: [
                        {
                            label: '产业规模',
                            title: '产业发展指数-产业规模',
                            num: '562',
                            path: '/phone/data',
                            state: '01',
                        },
                        {
                            label: '电商转型',
                            title: '产业发展指数-电商转型',
                            num: '562',
                            path: '/phone/data',
                            state: '02',
                        },
                        {
                            label: '产业效益',
                            title: '产业发展指数-产业效益',
                            num: '562',
                            path: '/phone/data',
                            state: '03',
                        },
                    ]
                },
                {
                    label: '时尚创新指数',
                    title: '时尚创新指数',
                    num: '563',
                    path: '/phone/data',
                    state: 'root',
                    children: [
                        {
                            label: '研发创新',
                            title: '时尚创新指数-研发创新',
                            num: '563',
                            path: '/phone/data',
                            state: '01',
                        },
                        {
                            label: '品牌运营',
                            title: '时尚创新指数-品牌运营',
                            num: '563',
                            path: '/phone/data',
                            state: '02',
                        },
                        {
                            label: '时尚设计',
                            title: '时尚创新指数-时尚设计',
                            num: '563',
                            path: '/phone/data',
                            state: '03',
                        },
                    ]
                },
                {
                    label: '产业景气指数',
                    title: '产业景气指数',
                    num: '564',
                    path: '/phone/data',
                    state: 'root',
                    children: [
                        {
                            label: '生产景气',
                            title: '产业景气指数-生产景气',
                            num: '564',
                            path: '/phone/data',
                            state: '01',
                        },
                        {
                            label: '市场景气',
                            title: '产业景气指数-市场景气',
                            num: '564',
                            state: '02',
                            path: '/phone/data',
                        }
                    ]
                },
                ]
            },
            {
                title: '指数点评',
                label: '指数点评',
                path: '/phone/comment'
            },
            {
                label: '新闻资讯',
                title: '新闻资讯',
                path: '/phone/news'
            },
            {
                label: '优质企业',
                title: '优质企业',
                path: '/phone/enter'
            },
            {
                label: '指数介绍',
                title: '指数介绍',
                path: '/phone/introduce'
            },
            {
                label: '关于白沟',
                title: '关于白沟',
                path: '/phone/about'
            },
            // {
            //     label: '信息披露',
            //     title: '信息披露',
            //     path: '/phoneInfo'
            // },
            // {
            //     label: '公开声明',
            //     title: '公开声明',
            //     path: '/phoneStatement'
            // },
            ],
            defaultProps: {
                children: 'children',
                label: 'label'
            }
        }
    },
    watch: {
        $route(val, old) {
            if (val.query.data.title) {
                this.title = val.query.data.title
            }
            this.show = false
        }
    },
    methods: {
        handleNodeClick(data) {
            if (data.title) {
                this.title = data.title
            }

            if (data.path) {
                this.$router.replace({
                    path: data.path,
                    query: {
                        data: data
                    }
                })
            } else {

            }

        },
        onClickLeft() {
            this.show = true
        },
        onClickRight() {
            // this.title='首页'
            this.$router.replace({
                path: '/phone/home',
                query: {
                    data: {
                        title: '首页'
                    }
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
/deep/.van-nav-bar__content {
    height: 0.3rem;
    background-color: #814f23;
    
}

/deep/.van-nav-bar__title {
    font-size: 0.16rem;
    line-height: 0.16rem;
}

/deep/.van-ellipsis {
    height: 0.16rem;
    overflow: inherit;
    color: #fff !important;
}

.nav-bar{
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 99999;
}

.list_nav {
    width: 2rem;
    height: 6.67rem;
    margin-top: 0.5rem;

    .block_list {
        width: 100%;
        height: 1rem;
    }

    /deep/.el-tree-node__label {
        font-size: 0.2rem;
    }

    /deep/.el-tree-node__content {
        width: 100%;
        height: 0.5rem;
        font-size: 0.25rem;
    }
    /deep/.van-icon-wap-nav:before{
        color: #fff !important;
    }
    /deep/.el-tree-node__expand-icon {
        font-size: 0.2rem;
    }
}
</style>