<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      isMobile: true,
    }
  },
  watch: {
    isMobile(val, old) {
      if (val) {
        this.setRem()
      } else {
        document.documentElement.style.fontSize = 16 + 'px'
        this.$router.replace({
          path: `/home`,
        })
      }
    }
  },
  mounted() {
    console.log(window.location);
    var isMobile = /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)
    if (isMobile) {
      this.setRem()
      // this.state = false
      this.$router.replace({
        path: window.location.hash.indexOf('phone')==-1?  `/phone${window.location.hash.replace('#', '')}`:window.location.hash.replace('#', ''),
      })
      // window.location.href = window.location.origin + '/#/' + 'phone' + window.location.hash.replace('#', '')
    } else {
      // this.$router.replace({
      //       path: `/home`,
      //     })
      // this.state = true
      document.documentElement.style.fontSize = 16 + 'px'
    }
    // window.onresize = () => {
    //   return (() => {
    //     var isMobile = /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)
    //     if (isMobile) {
    //       this.setRem()
    //       this.state = false
    //       this.$router.replace({
    //         path: `/phone/home`,
    //       })
    //     } else {
    //       this.state = true
    //       document.documentElement.style.fontSize = 16 + 'px'
    //       this.$router.replace({
    //         path: `/home`,
    //       })
    //     }
    //   })()
    // };
  },
  methods: {
    setRem() {
      const scale = document.documentElement.clientWidth / 375 * 100; document.documentElement.style.fontSize = scale + 'px';
    },
  },
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
